<template>
  <div class="relative flex md:h-screen bg-endark">
    <div class="p-6 m-auto text-center sm:text-left">
      <h1
        class="mt-4 text-6xl font-medium tracking-tight md:mt-0 md:text-7xl lg:text-8xl font-poppins text-enorange"
      >
        Enchanter
      </h1>
      <p
        class="mt-1 text-xl font-medium tracking-tight md:text-2xl font-poppins text-engreen"
      >
        ‘CHan(t)ər &nbsp; / &nbsp; Name
      </p>

      <p
        class="mt-2 text-lg font-light leading-7 tracking-tight text-white sm:text-xl sm:leading-8 md:text-2xl md:leading-9 font-poppins"
      >
        <em
          >"Enchanter is a class of champions in League of Legends that focuses
          on amplifying their teammates’ effectiveness and abilities."</em
        >
      </p>

      <div class="mt-6 lg:hidden">
        <img
          class="w-full max-h-64"
          src="@/assets/img/main-illustration.svg"
          alt=""
        />
      </div>

      <h2
        class="mt-6 text-xl font-normal leading-8 tracking-tight text-white lg:mt-12 sm:text-2xl sm:leading-9 md:text-3xl md:leading-11 font-poppins"
      >
        I’m Antoine Frankart, a Consultant specialized in Digital Product
        Management, with 15+ years of experience in the Esports industry. 
        <br class="sm:hidden" />
        I can be your Enchanter.
      </h2>

      <div class="text-center lg:text-left">
        <a
          class="inline-block px-6 py-3 m-auto mt-6 text-lg font-normal tracking-tight text-white rounded-md shadow bg-engreen hover:bg-engreen-dark focus:outline-none sm:text-xl md:text-2xl font-poppins md:shadow-md"
          href="https://www.linkedin.com/in/emerika/"
        >
          <svg
            class="inline-block mr-2 text-white align-middle fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
              d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
            />
          </svg>
          Let's talk
        </a>
      </div>

      <div
        class="absolute hidden text-xs font-normal tracking-wider text-center text-white uppercase md:block right-14 bottom-16"
      >
        <div class="icon-scroll"></div>
        scroll down
      </div>
    </div>

    <div class="px-2 m-auto">
      <img
        class="hidden lg:block"
        src="@/assets/img/main-illustration.svg"
        alt=""
      />
    </div>
  </div>

  <Projects />

  <Skills />

  <Contact />

  <Footer />
</template>

<script>
import Projects from '@/components/Projects.vue';
import Skills from '@/components/Skills.vue';
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'HelloWorld',
  components: {
    Projects,
    Skills,
    Contact,
    Footer,
  },
};
</script>

<style scoped></style>
