<template>
  <div class="pt-24 pb-10 px-6">
    <div
      class="
        p-12
        md:p-24
        bg-contact-photo bg-center bg-opacity-0
        rounded-md
        shadow
        md:shadow-md
        overflow-hidden
      "
    >
      <div class="text-center sm:text-left">
        <h3
          class="
            text-3xl
            sm:text-4xl
            md:text-5xl
            font-medium
            text-white
            tracking-tight
          "
        >
          Sound like a fit?
        </h3>

        <a
          class="
            inline-block
            m-auto
            mt-6
            py-3
            px-6
            rounded-md
            bg-engreen
            hover:bg-engreen-dark
            focus:outline-none
            text-lg
            sm:text-xl
            md:text-2xl
            font-poppins font-normal
            text-white
            tracking-tight
            shadow
            md:shadow-md
          "
          href="https://www.linkedin.com/in/emerika/"
        >
          <svg
            class="inline-block align-middle mr-2 text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
              d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
            />
          </svg>
          Let's talk
        </a>
      </div>
    </div>
  </div>
</template>
