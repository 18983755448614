<template>
  <div class="pt-24 pb-10 px-6">
    <div class="text-center font-poppins">
      <h2
        class="
          text-3xl
          sm:text-4xl
          md:text-5xl
          font-medium
          text-enorange
          tracking-tight
        "
      >
        My Expertise
      </h2>
      <p class="mt-2 text-base sm:text-lg md:text-xl font-light text-white">
        I may help you from idea to functional product
      </p>
    </div>
    <div class="font-poppins text-white">
      <dl
        class="
          space-y-10
          lg:space-y-0 lg:grid lg:grid-cols-3
          mt-8
          gap-6
          md:mt-12 md:gap-8
          lg:mt-14 lg:gap-10
        "
      >
        <div v-for="skill in listSkills" :key="skill.name">
          <dt>
            <div
              class="
                flex
                items-center
                justify-center
                h-12
                w-12
                rounded-md
                bg-engreen
                text-white
              "
            >
              <component :is="skill.icon" class="h-6 w-6" aria-hidden="true" />
            </div>
            <h4 class="mt-4 text-2xl font-medium text-engreen">
              {{ skill.name }}
            </h4>
          </dt>
          <dd class="mt-2 text-base font-light">
            {{ skill.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import {
  BeakerIcon,
  ShieldCheckIcon,
  PuzzleIcon,
} from '@heroicons/vue/outline';

const listSkills = [
  {
    name: 'Product Strategy & Discovery',
    description:
      'I can assist your team in the product strategy and discovery: defining the vision, identifying business models, validating product ideas, prioritizing the features. Operating at various levels: a company, a product, or a feature.',
    icon: BeakerIcon,
  },
  {
    name: 'Esports Education',
    description:
      "I can help your team better understand the esports core concepts, functionally or technically: circuit, tournament, ranking, tiebreaker, bracket, match, etc.",
    icon: ShieldCheckIcon,
  },
  {
    name: 'Delivery & Implementation',
    description:
      'I can support your team, working with PO, designers & developers, by writing specifications, testing implementations of features, during the delivery process.',
    icon: PuzzleIcon,
  },
];

export default {
  name: 'Skills',
  setup() {
    return {
      listSkills,
    };
  },
};
</script>
